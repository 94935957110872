import React from 'react'
import { useNavigate } from 'react-router-dom'
import CartButton from './CartButton';

const NavbarV2 = (props) => {
    const nav = useNavigate();
    const onBack = () => {
        nav(-1);
    }
    
    if (props.versi === '2') {
        return (
            <div className='bg-white shadow fixed-top'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6'>
                        <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
                            <span className="navbar-brand" onClick={onBack}><i className='fa fa-arrow-left'></i></span>
                            <span className="navbar-brand">{props.title}</span>
                            <span className="navbar-brand"></span>
                        </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if(props.versi === '3') {
        return (
            <div className='bg-white shadow fixed-top'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-6'>
                        <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
                            <span className="navbar-brand" onClick={onBack}><i className='fa fa-arrow-left'></i></span>
                            <span className="navbar-brand">{props.title}</span>
                            <CartButton/>
                        </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  
}

export default NavbarV2