import React from 'react'
import BottomTabs from '../components/BottomTabs'
import Carousel from '../components/Carousel'
import LikeButton from '../components/LikeButton'
import NavbarV2 from '../components/NavbarV2'
import CardProduct from '../components/CardProduct'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
  } from "react-share";
import BottomTabsProduct from '../components/BottomTabsProduct'


const styles = {
    
}

const ProductViewScreen = () => {
  return (
    <div>
        <NavbarV2 title="View"/>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6  bg-white shadow'>
                    <Carousel height='300px'/>
                    <div className='card-body'>
                        <p className='card-text fs-5'>Paprika Bandung</p>
                        <div className='row justify-content-between'>
                            <div className='col'>
                                <p className='card-text text-primary fw-bold fs-5'>Rp. 5.000</p>
                            </div>
                            <div className='col text-end'>
                                <TwitterShareButton url={ window.location.href } className="me-3">
                                    <TwitterIcon size={30} round={true}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={ window.location.href } className="me-3">
                                    <FacebookIcon size={30} round={true}/>
                                </FacebookShareButton>
                                <TelegramShareButton url={ window.location.href } className="me-3">
                                    <TelegramIcon size={30} round={true}/>
                                </TelegramShareButton>
                                <EmailShareButton url={ window.location.href }>
                                    <EmailIcon size={30} round={true}/>
                                </EmailShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6  bg-white'>
                    <div className='card-body'>
                        <p className='card-text text-muted fs-5'>Deskripsi</p>
                        <p className='card-text'>I wouldn't count on "line-height" to prevent wrapping because it's possible the user has changed her font settings (for smaller text) in the browser. Likewise for large fonts, if you have fixed the height of the div to number of pixels, that could look terrible. Especially with mobile browsers and "retina" screens, I would try to leave the height of the div flexible if the design layout permits--which it should!</p>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6  bg-white'>
                    <div className='card-body'>
                        <p className='card-text text-muted fs-5'>Bagikan ke teman</p>
                        <div className='row justify-content-between'>
                            <div className='col '>
                                <TwitterShareButton url={ window.location.href } className="me-3">
                                    <TwitterIcon size={30} round={true}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={ window.location.href } className="me-3">
                                    <FacebookIcon size={30} round={true}/>
                                </FacebookShareButton>
                                <TelegramShareButton url={ window.location.href } className="me-3">
                                    <TelegramIcon size={30} round={true}/>
                                </TelegramShareButton>
                                <EmailShareButton url={ window.location.href }>
                                    <EmailIcon size={30} round={true}/>
                                </EmailShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6  bg-white'>
                    <div className='card-body'>
                        <p className='card-text text-muted fs-5'>Produk Lainnya</p>
                        <div className='row flex-nowrap'  style={{overflowX: 'auto'}}>
                            <CardProduct />
                            <CardProduct />
                            <CardProduct />
                            <CardProduct />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomTabsProduct/>
    </div>
  )
}

export default ProductViewScreen