import React from 'react'
import { useNavigate } from 'react-router-dom'
import BottomTabs from '../components/BottomTabs'
import NavbarV2 from '../components/NavbarV2'

const RegisterScreen = () => {
    const nav = useNavigate();
  return (
    <div>
        <NavbarV2 title='Register' versi='2'/>
        <div className='container'>
            <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='row'>
                </div>
                <div className='row justify-content-center mt-5'>
                    <div className='col-md-6 card-body px-4'>
                        <div className='row justify-content-center'>
                            <div className='col-sm-7 card-body bg-white rounded'>
                                <div className='border border-info p-3 rounded mb-3'>
                                    <small className='text-muted'>Account Information</small>
                                    <div className='form-group  align-items-center mb-3'>
                                        <label className='col-md-3' for='email'>Email</label>
                                        <input type={'email'} className='form-control col' placeholder='Masukan email'></input>
                                    </div>
                                    <div className='form-group  align-items-center mb-3'>
                                        <label className='col-md-3' for='password'>Password</label>
                                        <input type={'password'} className='form-control col' placeholder='Masukan password'></input>
                                    </div>
                                </div>
                                <div className='border border-info p-3 rounded mb-3'>
                                    <small className='text-muted'>Other Information</small>
                                    <div className='form-group  align-items-center mb-3'>
                                        <label className='col-md-3' for='full_name'>Full Name</label>
                                        <input type={'text'} id="full_name" className='form-control col' placeholder='Masukan nama lengkap'></input>
                                    </div>
                                    <div className='form-group  align-items-center mb-3'>
                                        <label className='col-md-3' for='address'>Address</label>
                                        <textarea id="address"  className="form-control" placeholder="e.g. Perum. Taman Cendrawasih, Blok xx No.666 RT01/RW08. Tambun Utara. Kab.Bekasi"></textarea>
                                    </div>
                                    <div className='form-group  align-items-center mb-3'>
                                        <label className='col-md-3' for='phone'>Phone</label>
                                        <input type={'text'} id="phone" className='form-control col' placeholder='Masukan nomor telepon / hp'></input>
                                    </div>
                                </div>
                                <hr className=''></hr>
                                <div className='form-group d-flex align-items-center mb-3'>
                                    <button className='btn btn-outline-primary col w-50 me-3' onClick={() => nav('/login')} >Login</button>
                                    <button className='btn btn-primary col w-50'>Register</button>
                                </div>
                                <div className='form-group  align-items-center mb-3'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default RegisterScreen