import React from 'react'
import { useNavigate } from 'react-router-dom'
import BottomTabs from '../components/BottomTabs'
import NavbarV2 from '../components/NavbarV2'

const LoginScreen = () => {
    const nav = useNavigate();
  return (
    <div>
        <NavbarV2 title='Login' versi='2'/>
        <div className='container'>
            <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='row'>
                </div>
                <div className='row justify-content-center mt-5'>
                    <div className='col-md-6 card-body bg-white rounded'>
                        <div className='row justify-content-center'>
                            <div className='col-sm-7 card-body'>
                                <div className='form-group  align-items-center mb-3'>
                                    <label className='col-md-3' for='email'>Email</label>
                                    <input type={'email'} className='form-control col' placeholder='Masukan email'></input>
                                </div>
                                <div className='form-group  align-items-center mb-3'>
                                    <label className='col-md-3' for='password'>Password</label>
                                    <input type={'password'} className='form-control col' placeholder='Masukan password'></input>
                                </div>
                                <hr className=''></hr>
                                <div className='form-group d-flex align-items-center mb-3'>
                                    <button className='btn btn-outline-primary col mb-1 me-3 w-50' onClick={() => nav('/register')}>Register</button>
                                    <button className='btn btn-primary col w-50' >Login</button>
                                </div>
                                <div className='form-group  align-items-center mb-3'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default LoginScreen