import React from 'react'
import { useNavigate } from 'react-router-dom'

const BottomTabs = () => {
    const nav = useNavigate();
  return (
    <div className=' bg-white shadow-lg fixed-bottom'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
                    <span className="btn" onClick={() => nav('/')}><i className='fa fs-4 fa-home'></i></span>
                    <span className="btn" onClick={() => nav('/')}><i className='fa fs-4 fa-globe'></i></span>
                    <span className="btn" onClick={() => nav('/')}><i className='fa fs-4 fa-burger'></i></span>
                    <span className="btn" onClick={() => nav('/login')}> <i className='fa fs-4 fa-user'></i></span>
                </nav>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BottomTabs