import React from 'react'
import { useNavigate } from 'react-router-dom'

const CartButton = () => {
    const nav = useNavigate();
  return (
    <div onClick={() => nav('/cart')}>
        <span className="">
            <div>
                <small style={{fontSize: '12px'}} className='badge bg-danger text-light rounded-circle'>99</small>
                <i className='fa fa-shopping-cart'></i>
            </div>
        </span>
    </div>
  )
}

export default CartButton