import React from 'react'
import BottomTabs from '../components/BottomTabs'
import Carousel from '../components/Carousel'
import LikeButton from '../components/LikeButton'
import NavbarV2 from '../components/NavbarV2'
import CardProduct from '../components/CardProduct'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
  } from "react-share";
import BottomTabsProduct from '../components/BottomTabsProduct'
import { CartProvider, useCart } from "react-use-cart";


const styles = {
    image: {
        height: '200px',
        width: '100%',
        objectFit: 'cover',
    },
    crop: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'cover',
    },
}


function Cart() {
    const {
      isEmpty,
      totalUniqueItems,
      items,
      updateItemQuantity,
      removeItem,
    } = useCart();
  
    if (isEmpty) return <p>Your cart is empty</p>;
  
    return (
      <>
        <h1>Cart ({totalUniqueItems})</h1>
  
        {items.map((item) => (
            <div className='containerCartItem' key={item.id}>
                <div className='row'>
                    <div className='col-md-1 col-1 d-flex align-items-center'>
                        <input style={{ transform: 'scale(1.5)' }} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className='col-md-3 col-4 text-center d-flex align-items-center'>
                            <img style={{objectFit:'cover', height:'125px', width:'125px', borderRadius: '16px'}} src="https://bulma.io/images/placeholders/640x360.png" alt="... kiw"/>
                    </div>
                    <div className='col-md-8 col-7 align-items-start'>
                        <p className='crop fw-bold'>{item.name}</p>
                        <p className='crop fw-bold text-primary'>Rp. {item.price}</p>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-md-1 col-1 d-flex align-items-center'>
                        &nbsp;
                    </div>
                    <div className='col-md-4 col-4 text-center d-flex align-items-center'>
                        <small className='crop text-muted mt-2'>Stok tersedia : 30</small>
                    </div>
                    <div className='col-md-3 col-3 d-flex align-items-center'>
                        <span className='btn' onClick={() => updateItemQuantity(item.id, item.quantity - 1)}><i className='fa fa-minus-circle fs-4 text-primary'></i></span>
                        <span className='fs-4 '>{item.quantity}</span>
                        <span className='btn' onClick={() => updateItemQuantity(item.id, item.quantity + 1)}><i className='fa fa-plus-circle fs-4 text-primary'></i></span>
                    </div>
                    <div className='col-md-4 col-4 text-end'>
                        <p className='crop fw-bold text-primary'>Rp. {item.itemTotal}</p>
                    </div>
                </div>
                <hr></hr>
            </div>
        ))}
      </>
    );
  }

const CartScreen = () => {
    // const {
    //     isEmpty,
    //     totalUniqueItems,
    //     items,
    //     updateItemQuantity,
    //     removeItem,
    //   } = useCart();

    const products = [
        {
        id: 1,
        name: "Malm",
        price: 9900,
        quantity: 1
        },
        {
        id: 2,
        name: "Nordli",
        price: 16500,
        quantity: 5
        },
        {
        id: 3,
        name: "Kullen",
        price: 4500,
        quantity: 1
        },
    ];

  return (
    <div>
        <NavbarV2 title="Cart"/>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6  bg-white shadow'>
                    <div className='card-body text-center'>
                        {/* <p className='card-text'><i className='fa fa-info-circle text-primary'></i> Gratis Ongkir </p> */}
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6 col-12 bg-white'>
                    <div className='card-body'>
                        <Cart/>

                        {/* <div className='row'>
                            <div className='col-md-1 col-1 d-flex align-items-center'>
                                <input style={{ transform: 'scale(1.5)' }} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className='col-md-3 col-4 text-center d-flex align-items-center'>
                                  <img style={{objectFit:'cover', height:'125px', width:'125px', borderRadius: '16px'}} src="https://bulma.io/images/placeholders/640x360.png" alt="... kiw"/>
                            </div>
                            <div className='col-md-8 col-7 align-items-start'>
                                <p className='crop fw-bold'>Papprika Indonesia  Papprika IndonesiaPapprika IndonesiaPapprika IndonesiaPapprika IndonesiaPapprika Indonesia</p>
                                <p className='crop fw-bold text-primary'>Rp. 5000</p>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-1 col-1 d-flex align-items-center'>
                                &nbsp;
                            </div>
                            <div className='col-md-3 col-4 text-center d-flex align-items-center'>
                                <small className='crop text-muted mt-2'>Stok tersedia : 30</small>
                            </div>
                            <div className='col-md-8 col-7 text-end'>
                                <span className='me-3'><i className='fa fa-minus-circle fs-4 text-primary'></i></span>
                                <span className='fs-4 me-3'>1</span>
                                <span className=''><i className='fa fa-plus-circle fs-4 text-primary'></i></span>
                            </div>
                        </div>
                        <hr></hr> */}
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6  bg-white'>
                    <div className='card-body'>
                        <p className='card-text text-muted fs-5'>Bagikan ke teman</p>
                        <div className='row justify-content-between'>
                            <div className='col'>
                                <TwitterShareButton url={ window.location.href } className="me-3">
                                    <TwitterIcon size={30} round={true}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={ window.location.href } className="me-3">
                                    <FacebookIcon size={30} round={true}/>
                                </FacebookShareButton>
                                <TelegramShareButton url={ window.location.href } className="me-3">
                                    <TelegramIcon size={30} round={true}/>
                                </TelegramShareButton>
                                <EmailShareButton url={ window.location.href }>
                                    <EmailIcon size={30} round={true}/>
                                </EmailShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-6  bg-white'>
                    <div className='card-body'>
                        <p className='card-text text-muted fs-5'>Produk Lainnya</p>
                        <div className='row flex-nowrap'  style={{overflowX: 'auto'}}>
                            {products.map((p) => (
                                <CardProduct product={p} id={p.id} name={p.name}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomTabs/>
    </div>
  )
}

export default CartScreen