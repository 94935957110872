import React from 'react'
import { useNavigate } from 'react-router-dom';
import LikeButton from './LikeButton';
import { CartProvider, useCart } from "react-use-cart";


const styles = {
    image: {
        height: '200px',
        width: '100%',
        objectFit: 'cover',
    },
    crop: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'cover',
    },
}


const CardProduct = (props) => {
    const nav = useNavigate();
    const { addItem } = useCart();

    const viewProduct = () => {
        nav('/view')
    };

    return (
        <div className='col-6 mb-3'>
            <div className="card p-3" style={{ height: '360px'}}>
                <img style={styles.image} src="https://images.unsplash.com/photo-1573067485648-4cb2618b5e09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 style={styles.crop} className="card-title">{props.name}</h5>
                    <p className="card-text">Rp.5000</p>
                    <button className='btn btn-primary w-100' onClick={ () => addItem(props.product) }>Tambah</button>
                </div>
            </div>
        </div>
    )
}

export default CardProduct