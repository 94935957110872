import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ProductViewScreen from './screens/ProductViewScreen';
import CartScreen from './screens/CartScreen';
import HomeScreen from './screens/HomeScreen';
import CartDemo from './screens/CartDemo';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';

function App() {
  return (
    <div className='pt-2 mt-5 pb-5 mb-5'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen/>} />
          <Route path="/view" element={<ProductViewScreen/>} />
          <Route path="/login" element={<LoginScreen/>} />
          <Route path="/register" element={<RegisterScreen/>} />
          <Route path="/cart" element={<CartScreen/>} />
          <Route path="/cart-demo" element={<CartDemo/>} />
        </Routes>
      </BrowserRouter>
    </ div>
  );
}

export default App;
