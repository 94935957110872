import React from 'react'

const NavbarV1 = () => {
  return (
    <div className=' bg-white shadow fixed-top'>
      <div className='container'>
          <div className='row justify-content-center'>
              <div className='col-md-6'>
              <nav className="navbar navbar-expand-lg navbar-light justify-content-between ">
                  <a className="navbar-brand" href="/">{process.env.REACT_APP_NAME}</a>
                  <a className="navbar-brand" href="/"> <i className='fas fa-search'></i></a>
              </nav>
              </div>
          </div>
      </div>
    </div>

  )
}

export default NavbarV1