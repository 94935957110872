import React from 'react'
import { useNavigate } from 'react-router-dom'

const BottomTabsProduct = () => {
    const nav = useNavigate();
  return (
    <div className=' bg-white shadow-lg fixed-bottom'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                <nav className="navbar navbar-expand-lg navbar-light justify-content-between gy-3">
                    <span className="btn btn-outline-primary w-100 me-1 py-2" onClick={() => nav('/')}>Tambah</span>
                    <span className="btn btn-primary w-100 ms-1 py-2" onClick={() => nav('/')}>Beli</span>
                </nav>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BottomTabsProduct