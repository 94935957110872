import React from 'react'
import BottomTabs from '../components/BottomTabs'
import CardProduct from '../components/CardProduct'
import Carousel from '../components/Carousel'
import NavbarV1 from '../components/NavbarV1'

const HomeScreen = () => {
  return (
    <div>
        <NavbarV1/>
        <div className='container'>
            <div className='row justify-content-center'>
            <div className='col-md-6'>
                <div className='row'>
                  <Carousel height="200px"/>
                </div>
                <div className='row justify-content-center mt-5'>
                  <CardProduct/>
                  <CardProduct/>
                </div>
            </div>
            </div>
        </div>
        <BottomTabs/>
    </div>
  )
}

export default HomeScreen